import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import classNames from "classnames";
import DateSelectorItem from "./DateSelectorItem";
import range from "lodash/range";
import { FormattedDate, FormattedMessage } from "react-intl";
import "./DateSelector.scss";
import { checkDateValidity } from "app/utils/utils";
import IconLeft from "app/pages/.shared/static/icons/IconLeft";

const SELECTORS = {
	DAY: 0,
	MONTH: 1,
	YEAR: 2,
};

const dateTitle = {
	[SELECTORS.DAY]: <FormattedMessage id="date.selector.choose.day.label" />,
	[SELECTORS.MONTH]: <FormattedMessage id="date.selector.choose.month.label" />,
	[SELECTORS.YEAR]: <FormattedMessage id="date.selector.choose.year.label" />,
};

const DateSelector = ({ onChange, maxYear = new Date().getFullYear(), minYear = 1900 }) => {
	const [selectedDate, setSelectedDate] = useState({
		day: undefined,
		month: undefined,
		year: undefined,
	});
	const [visibleSelector, setVisibleSelector] = useState(SELECTORS.DAY);

	const finalize = useCallback(
		(day, month, year) => {
			if (!checkDateValidity(year, month, day)) {
				resetSelection();
			} else if (typeof onChange === "function") {
				onChange({
					day,
					month: month ? month - 1 : undefined,
					year,
				});
			}
		},
		[onChange]
	);

	const onDateSelect = useCallback(
		(type, value, nextSelector) => {
			const updatedDate = { ...selectedDate, [type]: value };
			setSelectedDate(updatedDate);

			if (type === "year") {
				finalize(updatedDate.day, updatedDate.month, updatedDate.year);
			} else {
				setVisibleSelector(nextSelector);
			}
		},
		[selectedDate, finalize]
	);

	const resetSelection = useCallback(() => {
		setSelectedDate({ day: undefined, month: undefined, year: undefined });
		setVisibleSelector(SELECTORS.DAY);
		if (typeof onChange === "function") {
			onChange({
				day: undefined,
				month: undefined,
				year: undefined,
			});
		}
	}, [onChange]);

	const renderMonthLabel = useCallback(month => {
		if (month >= 1) {
			const thisYear = new Date().getFullYear();
			return <FormattedDate value={new Date(thisYear, month - 1, 15)} month="long" />;
		}
		return null;
	}, []);

	const handleBackNavigation = useCallback(() => {
		if (visibleSelector === SELECTORS.MONTH) {
			setVisibleSelector(SELECTORS.DAY);
			setSelectedDate(prev => ({ ...prev, month: undefined }));
		} else if (visibleSelector === SELECTORS.YEAR) {
			setVisibleSelector(SELECTORS.MONTH);
		}
	}, [visibleSelector]);

	const title = dateTitle[visibleSelector] || null;

	const renderSelectorItems = (
		items,
		selectedItem,
		onSelectType,
		nextSelector,
		renderLabel,
		dataTestIdPrefix
	) => {
		return items.map(item => (
			<DateSelectorItem
				isActive={selectedItem === item}
				key={item}
				value={item}
				onClick={() => onDateSelect(onSelectType, item, nextSelector)}
				label={renderLabel(item)}
				data-testid={`${dataTestIdPrefix}-${item}`}
			/>
		));
	};

	const dayClass = classNames("date-selector__selector date-selector__selector--day", {
		"date-selector__selector--visible": visibleSelector === SELECTORS.DAY,
	});

	const monthClass = classNames("date-selector__selector date-selector__selector--month", {
		"date-selector__selector--visible": visibleSelector === SELECTORS.MONTH,
	});

	const yearClass = classNames("date-selector__selector date-selector__selector--year", {
		"date-selector__selector--visible": visibleSelector === SELECTORS.YEAR,
	});

	return (
		<div className="date-selector">
			<div className="date-selector__actions">
				{visibleSelector !== SELECTORS.DAY ? (
					<IconLeft width={20} height={20} onClick={handleBackNavigation} />
				) : (
					<span style={{ width: "20px" }} />
				)}
				<div>{title}</div>
				<span style={{ width: "20px" }} />
			</div>
			<div className="date-selector__body">
				<div className={dayClass}>
					{renderSelectorItems(
						range(1, 32),
						selectedDate.day,
						"day",
						SELECTORS.MONTH,
						day => day,
						"date-input-day"
					)}
				</div>

				<div className={monthClass}>
					{renderSelectorItems(
						range(1, 13),
						selectedDate.month,
						"month",
						SELECTORS.YEAR,
						renderMonthLabel,
						"date-input-month"
					)}
				</div>

				<div className={yearClass}>
					{renderSelectorItems(
						range(maxYear + 1, minYear - 1),
						selectedDate.year,
						"year",
						null,
						year => year,
						"date-input-year"
					)}
				</div>
			</div>
		</div>
	);
};

DateSelector.propTypes = {
	onChange: PropTypes.func,
	maxYear: PropTypes.number,
	minYear: PropTypes.number,
};

export default DateSelector;
