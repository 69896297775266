import PropTypes from "prop-types";
import classNames from "classnames";
import { useCallback } from "react";

const DateSelectorItem = ({ label, value, isActive, onClick, ...props }) => {
	const handleClick = useCallback(() => {
		if (typeof onClick === "function") {
			onClick(value);
		}
	}, [onClick, value]);

	const clazz = classNames({
		"date-selector__item": true,
		"date-selector__item--selected": isActive,
	});

	let itemLabel = label;

	if (typeof label === "function") {
		itemLabel = label(value);
	}

	return (
		<div className={clazz} {...props} onClick={handleClick}>
			{itemLabel}
		</div>
	);
};

DateSelectorItem.propTypes = {
	isActive: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	value: PropTypes.any.isRequired,
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.element,
		PropTypes.func,
	]),
};

export default DateSelectorItem;
